// import logo from './moog.jpeg';
import './App.css';
import { useState } from 'react';

function App() {
  const [count, setCount] = useState(0);

  const increment = () => {
    setCount(count < 10 ? count + 1 : 0);
}

  return (
    <div className="App">
      <header className="App-header">
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
        <div>So, now what?</div>
        <div onClick={increment}>{count}</div> 
      </header>
    </div>
  );
}

export default App;
